<!--
File: PreparePlan.vue
Description: shows tabs (add works, edit Works, approve Plan) for Plan preparation 
-->
<template>
  <VueTabs @tab-change="handleTabChange">
    <v-tab id="tab0" :title="$t('tabs.add_works')">
    </v-tab>

    <v-tab id="tab1" :title="$t('tabs.edit_works')">
    </v-tab>

    <v-tab id="tab2" :title="$t('tabs.approve_plan')">
      <Plans />
    </v-tab>

    <Worklist v-show="showWorkList" @itemAssigned="itemAssigned" :step="active" />
  </VueTabs>
</template>
<script>
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'

  import Worklist from './Worklist'
  import Plans from './Plans.vue'

  export default {
    name: 'prepare-plan-form',
    data() {
      return {
        active: 'first',
        showWorkList: true,

        stepNames: ['first', 'second', 'third']
      }
    },

    components: {
      VueTabs,
      VTab,
      Worklist,
      Plans,
    },

    mounted() {
    },

    methods: {
      handleTabChange(tabIndex) { //, newTab, oldTab) {
        this.active = this.stepNames[tabIndex]
        this.showWorkList = tabIndex != 2
      },
      itemAssigned(type, itemId) {
        //console.log(`${type} ${itemId == -1 ? ' was un' : ' with the no itemId was '}assigned`)
      },
    },

    computed: {
    },
  }
</script>
<style lang="scss" scoped>
.vue-tabs .nav-tabs>li.active>a,
.vue-tabs .nav-tabs>li.active>a:hover,
.vue-tabs .nav-tabs>li.active>a:focus {
  background-color: #4caf50;
}
</style>